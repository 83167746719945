.continer {
    display: flex;
    flex-direction: column;
    .title {
        height: 100px;
        width: 100%;
        background-size: cover;
        background-position: center;
        background-color: white;
        position: relative;
        .backButton {
            transform: rotate(180deg);
            position: absolute;
            top: 23px;
            right: 23px;
            &:hover {
                color: #EC008C;
            }
        }
        h3 {
            color: black;
            font-size: 40px;
            font-weight: bold;
            width: 100%;
            height: 100%;
            background-color: rgba(255, 255, 255, 0.753);
            line-height: 100px;
        }
    }
    .middle {
        display: flex;
        justify-content: space-around;
        margin-top: 15px;
        .detail {
            text-align: right;
            width: 30%;
            padding: 10px;
            .quantity {
                display: flex;
                margin-top: 30px;
                justify-content: space-between;
                .quantityInput {
                    display: flex;
                    flex-direction: column;
                    label {
                        font-weight: bold;
                    }
                    input {
                        width: 150px;
                    }
                }
                .price {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    font-size: 25px;
                    font-weight: bold;
                }
            }
        }
        .uploadLile {
            text-align: right;
            width: 30%;
            padding: 10px;
            margin-right: 10px;
        }
    }
    .bottom {
        .boxTerms {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 15px;
            .checkedContiner {
                display: flex;
                align-items: center;
                margin: 20px;
                .main {
                    display: block;
                    position: relative;
                    padding-left: 30px;
                    margin-bottom: 0px;
                    cursor: pointer;
                    font-size: 18px;
                }
                
                /* Hide the default checkbox */
                .main input[type=checkbox] {
                    visibility: hidden;
                }
                
                /* Creating a custom checkbox
                based on demand */
                .geekmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    left: 8px;
                    height: 25px;
                    width: 25px;
                    border: solid 2px #EC008C;
                    border-radius: 10px;
                }
                .geekmark_all {
                    left: 0px;
                }
                /* Specify the background color to be
                shown when hovering over checkbox */
                .main:hover input ~ .geekmark {
                    border: solid 3px #EC008C;
                }
                
                /* Specify the background color to be
                shown when checkbox is active */
                .main input:active ~ .geekmark {
                    background-color: red;
                }
                
                /* Specify the background color to be
                shown when checkbox is checked */
                .main input:checked ~ .geekmark {
                    border: solid 2px rgb(0, 183, 255);
                    background-color: rgb(0, 183, 255);
                }
                
                /* Checkmark to be shown in checkbox */
                /* It is not be shown when not checked */
                .geekmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
                
                /* Display checkmark when checked */
                .main input:checked ~ .geekmark:after {
                    display: block;
                }
                
                /* Styling the checkmark using webkit */
                /* Rotated the rectangle by 45 degree and 
                showing only two border to make it look
                like a tickmark */
                .main .geekmark:after {
                    left: 8px;
                    bottom: 6px;
                    width: 6px;
                    height: 12px;
                    border: solid white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }
            }
            .boxTermsAll {
                width: 50%;

                h3 {
                    width: 100%;
                }
               .boxTermsText{
                   width: 100%;
                   height: 200px;
                   overflow-y: scroll;
                   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.05);
                   border-radius: 15px;
                   padding: 10px;
               } 
            }
            .contining_buttons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                .button {
                    height: 40px;
                    width: 150px;
                    border-radius: 10px;
                    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
                    font-weight: bold;
                    font-size: 18px;
                    vertical-align: middle;
                    line-height: 40px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    cursor: pointer;
                    background-color: rgb(238, 238, 238);
                    margin-right: 30px;
                }
                .button:hover {
                    background-color: #EC008C;
                    color: white;
                }
            }
        }
    }
}
.err {
    color: red;
}
