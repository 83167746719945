.continer {
   position: relative;
   height: 33vw;
   display: flex;
   justify-content: flex-end;
   margin-bottom: 3vw;
   .right_content {
      position: absolute;
      right: 10vw;
      height: 33vw;
      display: flex;
      flex-direction: column;
      align-items: right;
      justify-content: center;
      z-index: 20;
      .black_box {
         background-color: black;
         height: 10vw;
         width: 25vw;
         color: white;
         text-align: right;
         padding-right: 1.5vw;
         display: flex;
         flex-direction: column;
         justify-content: center;
         .top_text {
            font-weight: 900;
            font-size: 4vw;
            line-height: 4vw;
         }
         .bottom_text {
            font-weight: 900;
            font-size: 2.5vw;
         }
      }
      .text_box {
         text-align: right;
         font-weight: 500;
         font-size: 1.8vw;
         margin-top: 1vw;
      }
      .button_box {
         margin-top: 1vw;
         font-weight: 500;
         background: #ff1694;
         border-radius: 2vw;
         color: white;
         font-size: 1.2vw;
         width: fit-content;
         padding: 0.3vw 1.2vw 0.3vw 1.2vw;
         cursor: pointer;
         // border-width: 2px;
         border: 2px solid #ff1694;
         &:hover {
            background-color: white;
            color: black;
         }
      }
   }
}
