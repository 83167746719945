.footer {
    width: 100vw;
    .continer {
        width: 100vw;
        background-color: #000000;
        padding-top: 55px;
        padding-bottom: 80px;
        padding-right: 130px;
        padding-left: 70px;
        display: flex;
        justify-content: space-between;
        .continer_right {
            display: flex;
            .right_content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .title {
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 900;
                    font-size: 22px;
                    text-align: right;
                    color: #ffffff;
                    cursor: pointer;
                    margin-bottom: 15px;
                    margin-left: 100px;
                }
                .text_tab {
                    cursor: pointer;
                    font-family: "Rubik";
                    font-weight: 100;
                    font-size: 18px;
                    text-align: right;
                    color: #ffffff;
                    margin-bottom: 10px;
                    padding: 0px;
                }
                .active_text_tab {
                    color: #ff1694;
                }
            }
        }
        .continer_left {
            .item_left {
                display: flex;
                justify-content: left;
                align-items: center;
                height: 50px;
                align-items: 50px;
                cursor: pointer;
                .icon_text {
                    font-style: normal;
                    font-weight: 100;
                    font-size: 18px;
                    color: #ffffff;
                }
                .icon {
                    margin-right: 24px;
                }
            }
        }
    }
    .botton_footer {
        background: #c4c4c4;
        // height: 60px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        .botton_footer_right {
            padding: 22px;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 300;
            font-size: 18px;
            line-height: 24px;
            text-align: right;
            color: #000000;
        }
        .botton_footer_left {
            display: flex;
            justify-content: left;
            padding-left: 40px;
            align-items: center;
            .botton_footer_payment {
                margin-left: 10px;
            }
        }
    }
}
