.continer {
    font-family: "Heebo";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    text-align: right;
    color: #000000;
    span {
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            text-shadow: 0 0 2px rgba(156, 156, 156, 0.685);
        }
    }
}
