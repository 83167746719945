.continer {
    display: flex;
    justify-content: space-around;
    .right {
        width: 50vw;
        margin-bottom: 200px;
        h2 {
            font-weight: 500;
            font-size: 36px;
            line-height: 53px;
            text-align: right;
            color: #000000;
            margin-top: 90px;
        }
        .celect_all_continer {
            display: flex;
            align-items: center;
            margin-top: 80px;
            margin-bottom: 60px;
            .celect_all_continer_text {
                width: 100px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
            }
            input {
                width: fit-content;
                margin-left: 5px;
            }
        }
        .contining_carts_title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 15px;
            border-bottom-width: 1px;
            border-bottom-color: black;
            border-bottom-style: solid;
            padding-left: 40px;
            .contining_carts_title_item {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
            }
        }
        .contining_carts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            min-height: 160px;
            margin-bottom: 15px;
            border-bottom-width: 1px;
            border-bottom-color: black;
            border-bottom-style: solid;
            align-items: center;
            padding-bottom: 7px;
            input {
                width: fit-content;
                margin-left: 10px;
            }
            .image_and_product_name {
                .contind_img_carts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 160px;
                    height: 160px;
                }
                .contind_img_carts img {
                    width: 160px;
                    height: 160px;
                    object-fit: cover;
                }
                .product_name {
                    margin-top: 8px;
                    width: 100%;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    color: #000000;
                }
            }
            .details_box {
                .contind_details_carts {
                    text-align: right;
                    margin: 10px;
                    margin-right: 20px;
                    height: 100%;
                    width: 100%;
                    width: 15vw;
                    max-height: 150px;
                    overflow: hidden;

                    .contind_details_carts_b {
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 26px;
                        text-align: right;
                    }
                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0px;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 26px;
                        text-align: right;
                    }
                }
                .read_more_box {
                    margin-right: 20px;
                }
            }
            .carts_contining_c {
                display: flex;
                .carts_quantity {
                    border: 1px solid rgba(0, 0, 0, 0.8);
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    width: fit-content;
                    padding: 6px;
                    padding-left: 10px;
                    min-width: 85px;
                    height: fit-content;
                }
                .carts_price {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    white-space: nowrap;
                    margin-right: 40px;
                    margin-left: 40px;
                    margin-top: 7px;
                }
                .carts_trash {
                    color: rgb(110, 110, 110);
                    cursor: pointer;
                    transform: scale(0.8);
                }
            }
        }
    }
    .left {
        display: flex;
        flex-direction: column;
        padding-right: 30px;
        width: 30vw;
        padding-top: 200px;
        .contining_carts_sum {
            width: 30vw;
            margin-top: 10px;
            border: 1px solid black;
            .contining_carts_info {
                padding-top: 24px;
                padding-left: 42px;
                padding-right: 42px;
                padding-bottom: 20px;
                h4 {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 29px;
                    text-align: right;
                }
                hr {
                    opacity: 1;
                    border-width: 0px;
                    margin-top: 20px;
                }
                .contining_carts_price {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 300px;
                    h5 {
                        font-weight: 500;
                        font-size: 30px;
                        line-height: 44px;
                    }
                }
            }
            .button_for_payment {
                height: 77px;
                width: 100%;
                vertical-align: middle;
                cursor: pointer;
                background-color: #ff1694;
                font-weight: 400;
                font-size: 18px;
                line-height: 77px;
                text-align: center;
                color: #ffffff;
            }
            .button_for_payment:hover {
                background-color: #ec008c;
                color: white;
            }
        }
        // .position_fixed {
        //     width: 30vw;
        //     position: fixed;
        //     top: 0px;
        // }
    }
}
