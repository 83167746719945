.continer {
    width: 100vw;
    background-color: black;
    padding-bottom: 150px;
    .continer_top {
        background-color: white;
        padding-right: 125px;
        padding-left: 125px;
        padding-bottom: 44px;
        padding-top: 66px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .buffer {
            background-color: black;
            height: 3px;
            width: 140px;
            margin-top: 58px;
            margin-bottom: 18px;
        }
        .title {
            font-family: "Heebo";
            font-style: normal;
            font-weight: 800;
            font-size: 60px;
            text-align: right;
            color: #000000;
        }
    }
    .items_continer {
        padding: 40px;
        display: flex;
        justify-content: center;
        .items_continer_box {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            align-items: flex-start;
            width: fit-content;
            .box_items_list {
                width: 317px;
                height: 427px;
                overflow: hidden;
                background-color: white;
                margin: 9px;
                cursor: pointer;
                .box_items_list_image {
                    img {
                        width: 317px;
                        height: 317px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
                .box_items_list_text {
                    height: 110px;
                    width: 100%;
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 500;
                    font-size: 38px;
                    text-align: right;
                    color: #000000;
                    padding: 18px;
                    line-height: 42px;
                }
            }
        }
    }
}
