

.App_continer {
  text-align: center;
  font-family: 'HEEBO';
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

