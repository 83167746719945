.continer {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 446px;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 8px 50px rgba(66, 66, 66, 0.534);
    z-index: 200;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    transition: 0.8s;
    transform: translateX(-700px);
    hr {
        opacity: 1;
        border-width: 0px;
        margin: 0px;
    }
    .close {
        cursor: pointer;
        position: absolute;
        top: 32px;
        left: 39px;
        transition: 0.5s;
        &:hover {
            transform: scale(1.2);
        }
    }
    h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 41px;
        margin-top: 30px;
        margin-bottom: 30px;
        margin-right: 38px;
        text-align: right;
    }
    .celect_all_continer {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 38px;
        .celect_all_continer_text {
            width: 100px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: right;
        }
        input {
            width: fit-content;
            margin-left: 5px;
        }
    }
    .carts_continer {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        padding-right: 38px;
        padding-left: 38px;
        .contining_carts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            border-bottom-width: 1px;
            border-bottom-color: black;
            border-bottom-style: solid;
            position: relative;
            padding-top: 43px;
            input {
                position: absolute;
                top: 90px;
                width: fit-content;
                margin-left: 10px;
                margin-right: -25px;
            }
            .contining_carts_right {
                margin-bottom: 37px;
                margin-left: 30px;
                .contind_img_carts {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-bottom: 18px;
                    width: 100px;
                    height: 100px;
                    img {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                    }
                }
                .trash_and_quantity {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    .carts_quantity {
                        height: 36px;
                        width: 100px;
                        border: 1px solid rgba(0, 0, 0, 0.8);
                        box-sizing: border-box;
                        border-radius: 5px;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 38px;
                        text-align: center;
                    }
                    .carts_trash_continer {
                        height: 38px;
                        width: 38px;
                        line-height: 38px;
                        border-radius: 5px;
                        border: 1px solid rgba(0, 0, 0, 0.8);
                        .carts_trash {
                            color: rgb(110, 110, 110);
                            cursor: pointer;
                            transform: scale(0.8);
                            &:hover {
                                color: rgb(59, 59, 59);
                            }
                        }
                    }
                }
            }
            .contining_carts_left {
                width: 230px;
                max-width: 250px;
                .product_name {
                    width: 100%;
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 30px;
                    text-align: right;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .contind_details_carts {
                    width: 100%;
                    margin-top: 10px;
                    height: 130px;
                    max-height: 60px;
                    .contind_details_carts_b {
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 20px;
                        text-align: right;
                    }
                    p {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        margin: 0px;
                        font-weight: 300;
                        font-size: 15px;
                        line-height: 20px;
                        text-align: right;
                    }
                    overflow: hidden;
                    text-align: right;
                }
                .carts_contining_c {
                    margin-top: 18px;
                    display: flex;
                    .carts_price {
                        font-weight: 500;
                        font-size: 20px;
                        // line-height: 35px;
                        text-align: right;
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: flex-end;
                        p {
                            font-weight: 300;
                            font-size: 18px;
                            // line-height: 20px;
                            margin: 0px;
                            margin-right: 24px;
                        }
                    }
                }
            }
        }
    }
    .contining_carts_sum {
        width: 100%;
        margin-top: 10px;
        .contining_carts_info {
            padding-top: 24px;
            padding-left: 42px;
            padding-right: 42px;
            padding-bottom: 20px;
            h4 {
                font-weight: 500;
                font-size: 20px;
                line-height: 29px;
                text-align: right;
            }
            .contining_carts_price {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 10px;
                margin-bottom: 10px;
                .inside {
                    display: flex;
                    align-items: center;
                }
                p {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: right;
                    margin: 0px;
                }
                h5 {
                    margin: 0px;
                    font-weight: 500;
                    font-size: 24px;
                    margin-left: 11px;
                }
            }
        }
        .button_for_payment {
            height: 70px;
            width: 100%;
            vertical-align: middle;
            cursor: pointer;
            background-color: #ff1694;
            font-weight: 400;
            font-size: 18px;
            line-height: 70px;
            text-align: center;
            color: #ffffff;
            border: 1px solid #ff1694;
        }
        .button_for_payment:hover {
            background-color: white;
            color: #ff1694;
        }
        .button_for_cart {
            margin-top: 10px;
            height: 70px;
            line-height: 70px;
            width: 100%;
            vertical-align: middle;
            cursor: pointer;
            font-weight: 400;
            font-size: 18px;
            text-align: center;
            color: black;
            border: 0.5px solid black;
            border-bottom-color: white;
        }
        .button_for_cart:hover {
            background-color: white;
            color: #ff1694;
            border: 1px solid #ff1694;
        }
    }
}

#contining_items_style_15::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

#contining_items_style_15::-webkit-scrollbar {
    cursor: pointer;
    width: 10px;
    background-color: #f5f5f5;
}

#contining_items_style_15::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#a0a0a0), to(#a0a0a0), color-stop(0.6, #ebebeb));
}
