.continer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;
    width: 100vw;
    background-color: black;
    overflow-x: hidden;
    .logo_continer {
        position: absolute;
        left: 0px;
        top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0px;
        width: 100vw;
        img {
           margin-top: 80px;
           cursor: pointer;
        }
    }
    .contining_right {
        display: flex;
        justify-content: right;
        align-items: center;
        
        .top_tab {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 24px;
            text-align: center;
            color: #ffffff;
            margin-right: 30px;
            cursor: pointer;
        }
        .active_top_tab {
            color: #ff1694;
        }
    }
    .contining_left {
        padding-left: 36px;
        display: flex;
        justify-content: left;
        align-items: center;
        .contining_left_buttoms {
            cursor: pointer;
        }
        .boundary_line {
            height: 37px;
            width: 0px;
            border: 2px solid #ffffff;
            margin-left: 21px;
            margin-right: 21px;
        }
        input {
            height: 40px;
            width: 240px;
            margin-left: 32px;
            border: 2px solid #ffffff;
            box-sizing: border-box;
            border-radius: 22px;
            background-color: black;
            color: white;
            padding-right: 20px;
        }
        ::placeholder {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 500;
            font-size: 19px;
            line-height: 24px;
            text-align: right;
            color: #ffffff;
        }
        .log_out {
            color: white;
            font-size: 35px;
            display: flex;
            align-items: center;
            transform: rotate(180deg);
            cursor: pointer;
        }
    }
}
@media only screen and (max-width: 1500px) {
    .continer {
        padding-top: 80px;
        padding-bottom: 20px;
    }
  }
