.continer {
    .file_box {
        display: flex;
        label {
            cursor: pointer;
            font-family: 'Heebo';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 26px;
            text-align: right;
            color: rgba(0, 0, 0, 0.56);
            display: flex;
            .file_title {
                font-family: 'Heebo';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 26px;
                text-align: right;
                color: #000000;
                min-width: 100px;
            }
            input {
                display: none;
            }
        }
    }
    .err {
        color: red;
    }
}
