.continer {
  background-color: #efeded;
  width: 100vw;
  padding-top: 180px;
  padding-bottom: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .ques_item_box {
    background: #cdcdcd;
    border-radius: 15px;
    margin-bottom: 30px;
    width: 80vw;
    .question_text {
      background: #000000;
      border-radius: 15px;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #ffffff;
      padding-left: 42px;
      padding-right: 42px;
      padding-top: 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
    .answer_text {
      padding-left: 42px;
      padding-right: 42px;
      font-weight: 300;
      font-size: 18px;
      line-height: 21px;
      text-align: right;
      color: #000000;
      margin-top: 23px;
      margin-bottom: 20px;
    }
  }
}
