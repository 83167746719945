.continer {
    .top_box {
        display: flex;
        justify-content: center;
        padding-bottom: 125px;
        .top_box_right {
            margin-left: 10vw;
            padding-top: 70px;
            .top_box_right_title {
                margin-top: 120px;
                margin-bottom: 14px;
                font-family: "Heebo";
                font-style: normal;
                font-weight: 800;
                font-size: 28px;
                line-height: 44px;
                text-align: right;
                color: #000000;
            }
            .contind_img_top img {
                margin-top: 20px;
                width: 25vw;
                height: 25vw;
                object-fit: cover;
            }
        }
        .top_box_left {
            width: 40vw;
            .titleForCustomer {
                margin-top: 140px;
                font-family: "Heebo";
                font-style: normal;
                font-weight: 800;
                font-size: 56px;
                line-height: 86px;
                text-align: right;
                color: #000000;
            }
            .selecting_all_properties_continer {
                .contining_properties_elements {
                    display: flex;
                    width: 100%;
                    margin-top: 8px;
                    margin-bottom: 8px;
                    .label_calculator_parameter {
                        font-family: "Heebo";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        text-align: right;
                        color: #000000;
                        margin-left: 7px;
                        min-width: fit-content;
                    }
                    input,
                    select {
                        font-family: "Heebo";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 21px;
                        text-align: right;
                        color: rgba(0, 0, 0, 0.56);
                        border-radius: 0px;
                        outline: 0;
                        border-color: rgba(0, 0, 0, 0.7);
                        border-width: 0 0 1px;
                        max-width: 100%;
                        padding: 0px;
                        background-color: rgba(255, 255, 255, 0);
                    }
                    input:focus {
                        border-color: #ff1694;
                    }
                }
                .sizes_box {
                    display: flex;
                    justify-content: space-between;
                    // background-color: aqua;
                    align-items: center;
                    height: 27px;
                    .label_sizes {
                        min-width: 100px;
                        font-size: 18px;
                        text-align: right;
                    }
                    .contining_properties_elements {
                        display: flex;
                        justify-content: space-between;
                        margin-right: 20px;
                        width: 100%;
                    }
                }
                .contining_input {
                    width: 100%;
                    input {
                           width: 100%;
                    }
                }
                .product_sum_price {
                    margin-top: 80px;
                    display: flex;
                    p {
                        font-family: "Rubik";
                        font-style: normal;
                        font-weight: 300;
                        font-size: 18px;
                        line-height: 21px;
                        text-align: right;
                        color: #000000;
                        margin-top: 6px;
                    }
                    h3 {
                        font-family: "Heebo";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 24px;
                        line-height: 35px;
                        text-align: right;
                        color: #000000;
                        margin-left: 20px;
                        margin-right: 20px;
                    }
                }
                .button {
                    height: 60px;
                    width: 100%;
                    border-radius: 10px;
                    cursor: pointer;
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                    color: #000000;
                    border: solid 1px #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 30px;
                }
                .button:hover {
                    border-color: #ec008c;
                    background-color: #ec008c;
                    color: white;
                }
                .button_payment {
                    height: 60px;
                    width: 100%;
                    border-radius: 10px;
                    cursor: pointer;
                    font-family: "Rubik";
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 28px;
                    text-align: center;
                    color: white;
                    background-color: black;
                    border: solid 1px #000000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 10px;
                    &:hover {
                        background-color: #ec008c;
                        border-color: #ec008c;
                    }
                }
                .terms {
                    font-family: "Heebo";
                    font-style: normal;
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: right;
                    color: #000000;
                    text-decoration: underline;
                    cursor: pointer;
                    &:hover {
                        color: #ec008c;
                    }
                }
            }
        }
    }
}
.contining_buttons {
    display: flex;
    padding: 20px;
}
.product_specifications {
    background-color: white;
    width: 600px;
    padding: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
    text-align: right;
}
.err_message {
    color: red;
    font-size: 10pt;
}
.invisible {
    display: none;
}
.label_calculator_parameter_paper {
    background-color: #f0f0f0;
    padding: 10px;
}
.bold_text {
    font-weight: bold;
}
.red_text {
    color: red;
}
.div_specifications_text {
    margin-bottom: 8px;
}
.price_to_manager {
    margin-right: 40px;
    /* float: left; */
    padding: 5px;
    background-color: blue;
    color: white;
    border-radius: 10px;
}
