.continer_all {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.continer_left {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.continer_right {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 30px;
}
.continer_body {
    display: flex;
    flex-direction: row;
}
.contining_right {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.contining_left {
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    position: -webkit-sticky;
    width: 400px;
}
.contining_title {
    position:absolute;
    display: flex;
    align-items: center;
    flex-direction: column;
    position:relative;
    padding-top: 10px;
    margin-bottom: 20px;
}
.line_below_title {
    background-color: #00AEEF;
    height: 7px;
    width: 80px;
}
.half_circle_title {
    position: relative;
    background-color: #FFF200;
    height: 100px;
    width: 200px;
    border-bottom-right-radius: 125px;
    border-bottom-left-radius: 125px;
}
.contining_orders_top {
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    width: 800px;
    padding: 20px;
    margin-top: 10px;
    text-align: right;
}
.contining_orders_top h4{
   margin-bottom: 20px;
}
.contining_orders {
    display: flex;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    width: 800px;
    padding: 10px;
    margin-top: 10px;
}
.contining_orders_sum {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    width: 400px;
    padding: 10px;
    margin-top: 10px;
}
/*  */
.main {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 18px;
}

/* Hide the default checkbox */
.main input[type=checkbox] {
    visibility: hidden;
}

/* Creating a custom checkbox
based on demand */
.geekmark {
    position: absolute;
    top: 0;
    left: 0;
    left: 8px;
    height: 25px;
    width: 25px;
    border: solid 2px #EC008C;
    border-radius: 10px;
}

/* Specify the background color to be
shown when hovering over checkbox */
.main:hover input ~ .geekmark {
    border: solid 3px #EC008C;
}

/* Specify the background color to be
shown when checkbox is active */
.main input:active ~ .geekmark {
    background-color: red;
}

/* Specify the background color to be
shown when checkbox is checked */
.main input:checked ~ .geekmark {
    border: solid 2px rgb(0, 183, 255);
    background-color: rgb(0, 183, 255);
}

/* Checkmark to be shown in checkbox */
/* It is not be shown when not checked */
.geekmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Display checkmark when checked */
.main input:checked ~ .geekmark:after {
    display: block;
}

/* Styling the checkmark using webkit */
/* Rotated the rectangle by 45 degree and 
showing only two border to make it look
like a tickmark */
.main .geekmark:after {
    left: 8px;
    bottom: 6px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.orders_contining_a {
    width: 50px;
    /* background-color: blue; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;
}
.orders_contining_b {
    width: 600px;
    /* background-color: violet; */
    display: flex;
}
.orders_contining_c {
    width: 130px;
    /* background-color: sienna; */
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.orders_contining_c p{
   font-size: 15px;
   font-weight: normal;
}
.contind_img_orders {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contind_img_orders img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
}
.contind_details_orders {
    text-align: right;
    margin: 10px;
    margin-right: 20px;
}
.orders_trash {
    color: rgb(110, 110, 110);
    cursor: pointer;
    transform: scale(0.8);
}
.orders_trash:hover {
    transform: scale(0.85);
    color: rgb(0, 0, 0);
}
.orders_price {
    font-weight: bold;
}
.button_for_payment {
    height: 40px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}
.button_for_payment:hover {
  background-color: #EC008C;
  color: white;
}
.position_fixed {
    position: fixed;
    top: 0px;
}
.contind_details_orders p{
    margin: 0px;
}
.contind_details_orders_b {
    font-weight: bold;
}
.contining_succeeded {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}
.div_succeeded{
    width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button_for_succeeded {
    height: 40px;
    /* width: 200px; */
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}
.button_for_succeeded:hover {
  background-color: #EC008C;
  color: white;
}
.check_double {
    color: #EC008C;
}
.contining_customer_selection {
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    width: 400px;
    padding: 20px;
    margin-top: 10px;
    text-align: right;
}
.button_customer_selection {
    padding: 2px;
    cursor: pointer;
}
.button_customer_selection:hover {
    color: #EC008C;
    font-weight: bold;
}
.account_details {
    /* width: 100%; */
    border-style: groove;
    border-radius: 10px;
    background-color: #f2f2f2;
    padding: 20px;
    margin-bottom: 20px;
    width: 800px;
}
.account_details input, select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  .err_message {
    color: red;
    font-size: 10pt;
  }
  .button {
    float: left;
    height: 40px;
    width: 200px;
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    line-height: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgb(255, 255, 255);
}
.button:hover {
  background-color: #EC008C;
  color: white;
}
.inputNum::-webkit-inner-spin-button, 
::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
