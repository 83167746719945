.continer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 72px;
    margin-bottom: 100px;
    .contining_right {
        .contind_img_productModelsSKU {
            overflow: hidden;
            margin-top: 35px;
            img {
                margin-top: 20px;
                width: 30vw;
                height: 30vw;
                object-fit: cover;
            }
        }
    }
    .contining_middle {
        margin-top: 82px;
        margin-left: 40px;
        margin-right: 40px;
        .models_continer {
            scroll-snap-type: y unset;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 70vh;
            padding-left: 20px;
            padding-right: 20px;
            .div_model_holder {
                margin-bottom: 34px;
                position: relative;
                scroll-snap-align: center;
                height: 10vw;
                width: 10vw;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
                overflow: hidden;
                cursor: pointer;
                transition: 0.2s;
                &:hover {
                    transform: scale(1.02);
                }
                img {
                    height: 10vw;
                    width: 10vw;
                    object-fit: cover;
                }
            }
        }
    }
    .contining_left {
        margin-top: 70px;
        .top_title {
            font-weight: 800;
            font-size: 40px;
            line-height: 59px;
            text-align: right;
            margin-bottom: 10px;
        }
        .normal_text {
            font-weight: 300;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
            max-width: 480px;
            .price {
                font-weight: 500;
                font-size: 24px;
                margin-left: 5px;
                margin-right: 5px;
            }
        }
        .contining_properties_elements {
            display: flex;
            justify-content: right;
            width: 100%;
            margin-top: 8px;
            margin-bottom: 8px;
            padding: 0px;
            .label_calculator_parameter {
                font-weight: 300;
                font-size: 18px;
                text-align: right;
                margin-left: 7px;
                min-width: fit-content;
                margin-right: 0px;
                padding-top: 5px;
            }
            input,
            select,
            textarea {
                margin-right: 10px;
                font-family: "Heebo";
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 21px;
                text-align: right;
                color: rgba(0, 0, 0, 0.56);
                border-radius: 0px;
                outline: 0;
                border-color: rgba(0, 0, 0, 0.7);
                border-width: 0 0 1px;
                max-width: 100%;
                padding: 0px;
                background-color: rgba(255, 255, 255, 0);
                margin-top: 5px;
                height: 25px;
                &:focus {
                    border-color: #ff1694;
                }
            }
        }
        .contining_buttons {
            margin-top: 30px;
            .button {
                height: 60px;
                width: 100%;
                border-radius: 10px;
                cursor: pointer;
                font-family: "Rubik";
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: #000000;
                border: solid 1px #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                &:hover {
                    background-color: black;
                    color: white;
                }
            }
            .button_payment {
                height: 60px;
                width: 100%;
                border-radius: 10px;
                cursor: pointer;
                font-family: "Rubik";
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                color: white;
                background-color: black;
                border: solid 1px #000000;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                &:hover {
                    background-color: #ec008c;
                    border-color: #ec008c;
                }
            }
        }
    }
}

#contining_items_style_15::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

#contining_items_style_15::-webkit-scrollbar {
    cursor: pointer;
    width: 10px;
    background-color: #f5f5f5;
}

#contining_items_style_15::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#a0a0a0), to(#a0a0a0), color-stop(0.6, #ebebeb));
}

/* @media (max-width: 768px) {
      .selectExtension-continer-text {
          text-align: center;
          padding-top: 70px;
      }
      .models_continer {
          scroll-snap-type: x mandatory;
          overflow-x: scroll;
          overflow-y: hidden;
          display: flex;
          align-items: center;
          -webkit-overflow-scrolling: touch;
          width: 100%;
      }
      
      .models_continer div{
          margin: 15px;
          position: relative;  
          border-radius: 10px;  
          scroll-snap-align: center;
          height: 200px;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
  } */
