.continer {
    position: fixed;
    bottom: 50px;
    left: 50px;
    z-index: 900;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    .text {
        font-weight: bold;
        margin-right: 8px;
    }
}
