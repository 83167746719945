.box_continer {
   position: absolute;
   top: 80px;
   overflow-y: hidden;
   padding-bottom: 200px;
   z-index: 100;
   .continer {
      width: 100vw;
      background-color: white;
      padding: 22px;
      padding-top: 0px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 100;
      box-shadow: 0px 8px 15px rgba(128, 128, 128, 0.623);
      transition: 1s;
      transform: translateY(-100vh);
      .box_item {
         padding-top: 22px;
         min-width: 200px;
         padding-left: 20px;
         padding-right: 20px;
         // background-color: aqua;
         .title {
            font-family: "Rubik";
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
            color: #000000;
            cursor: pointer;
         }
         .category_tab {
            cursor: pointer;
            font-family: "Rubik";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            text-align: center;
            color: #000000;
            margin-top: 12px;
         }
      }
   }
}
