.continer {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.705);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}
.div_succeeded{
    background-color: white;
    width: 500px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 20px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.button_for_succeeded {
    height: 40px;
    /* width: 200px; */
    border-radius: 10px;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(73, 73, 73, 0.19);
    font-weight: bold;
    font-size: 18px;
    vertical-align: middle;
    line-height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    background-color: rgb(238, 238, 238);
}
.button_for_succeeded:hover {
  background-color: #EC008C;
  color: white;
}
.check_double {
    color: #EC008C;
}