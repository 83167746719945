.continer {
    margin-top: 80px;
    .login_question {
        width: 100%;
        margin-bottom: 30px;
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
            span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                text-align: right;
                text-decoration: underline;
                cursor: pointer;
                &:hover {
                    color: #ec008c;
                }
            }
        }
    }
    .title {
        font-weight: 400;
        font-size: 24px;
        line-height: 35px;
        text-align: right;
        margin-bottom: 17px;
    }
    .contining_customer_details {
        .two_box_details {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .box_input_for_two {
                width: 48%;
            }
        }
        label {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
            width: 100%;
            margin-top: 30px;
        }
        input,
        select,
        textarea {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
            color: rgba(0, 0, 0, 0.56);
            border-radius: 0px;
            outline: 0;
            border-color: rgba(0, 0, 0, 0.7);
            border-width: 0 0 1px;
            width: 100%;
            padding: 0px;
            background-color: rgba(255, 255, 255, 0);
            margin-top: 8px;
            margin-bottom: 8px;
            padding-right: 8px;
        }
        input:focus {
            border-color: #ff1694;
        }
    }
}
.err_message {
    color: red;
    font-size: 10pt;
}
