.continer {
  display: flex;
  justify-content: center;
  .box {
    width: 450px;
    padding-top: 44px;
    padding-left: 66px;
    padding-right: 66px;
    padding-bottom: 67px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    margin-top: 50px;
    margin-bottom: 50px;
    h2 {
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #000000;
      margin-left: 70px;
      margin-right: 70px;
      margin-bottom: 26px;
    }
    .contining_properties_elements {
      width: 100%;
      margin-bottom: 27px;
      input,
      select {
        width: 100%;
        text-align: right;
        color: rgba(0, 0, 0, 0.56);
        border-radius: 0px;
        outline: 0;
        border-color: rgba(0, 0, 0, 0.7);
        border-width: 0 0 1px;
        max-width: 100%;
        padding: 0px;
        background-color: rgba(255, 255, 255, 0);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: right;
      }
      input:focus {
        border-color: #ff1694;
      }
      .err_message {
        color: red;
        font-size: 12px;
      }
    }
    .resetPassword {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      color: #ff1694;
      margin-top: 11px;
      margin-bottom: 12px;
      cursor: pointer;
      &:hover {
        color: #ec007e;
      }
    }
    .title_but {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      margin-top: 70px;
      margin-bottom: 7px;
    }
    .login_but {
      margin-top: 70px;
      background: #ff1694;
      border: 1px solid #ff1694;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #ffffff;
      cursor: pointer;
      margin-bottom: 16px;
      &:hover {
        background: white;
        color: #ff1694;
      }
    }
    .other_but {
      border: 1px solid #ff1694;
      box-sizing: border-box;
      border-radius: 12px;
      padding: 10px;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
      background: white;
      color: black;
      &:hover {
        background: #ff1694;
        color: #ffffff;
      }
    }
    .googel_but {
      position: relative;
      &:hover {
        p {
          color: #ff1694 !important;
        }
      }
      svg {
        display: none;
        position: fixed;
      }
      button {
        border: 1px solid #ff1694 !important;
        box-sizing: border-box !important;
        border-radius: 12px !important;
        padding: 10px !important;
        width: 100% !important;
        box-shadow: none !important;
      }
      span {
        padding: 0px !important;
        width: 100% !important;
      }
      .googel_but_in {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        p {
          font-weight: 400;
          font-size: 16px;
          margin-bottom: 0px;
          color: #000000;
        }
        img {
          position: absolute;
          right: 5px;
        }
      }
    }
  }
}
