.continer {
  width: 100%;
  .title {
    margin-top: 57px;
    margin-bottom: 30px;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    text-align: right;
  }
}
