.continer {
    border: 1px solid black;
    margin-top: 200px;
    padding-top: 22px;
    margin-bottom: 50px;
    .title {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        margin-right: 35px;
    }
    .category_titles {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;
        margin-right: 53px;
        margin-left: 53px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
        padding-bottom: 7px;
        padding-left: 7px;
        padding-right: 7px;
        .category_text {
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
        }
    }
    .product_list_box {
        height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-bottom: 10px;
        .contining_orders {
            margin-right: 53px;
            margin-left: 53px;
            padding-top: 36px;
            padding-bottom: 36px;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: space-between;
            .image_and_title {
                display: flex;
                h5 {
                    height: 48px;
                    width: 100px;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 26px;
                    text-align: right;
                    margin-right: 16px;
                }
                .contind_img_orders {
                    height: 50px;
                    width: 50px;
                    img {
                        width: 50px;
                        height: 50px;
                        object-fit: cover;
                        object-position: center;
                    }
                }
            }
            .orders_quantity {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
                width: 60px;
            }
            .orders_price_box {
                width: 107px;
                .orders_price {
                    font-size: 18px;
                    line-height: 26px;
                    text-align: right;
                }
            }
        }
    }
    .delivery_title {
        font-weight: 500;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        margin-right: 53px;
        margin-top: 40px;
    }
    .delivery_question_box {
        margin-top: 15px;
        margin-right: 53px;
        .delivery_line_box {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            input {
                width: fit-content;
                margin-left: 8px;
            }
            p {
                margin: 0px;
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
            }
        }
    }
    .contining_delivery_details {
        margin-right: 53px;
        margin-left: 53px;
        padding-top: 20px;
        input,
        select {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
            color: rgba(0, 0, 0, 0.56);
            border-radius: 0px;
            outline: 0;
            border-color: rgba(0, 0, 0, 0.7);
            border-width: 0 0 1px;
            max-width: 100%;
            padding: 0px;
            background-color: rgba(255, 255, 255, 0);
            margin-top: 8px;
            margin-bottom: 8px;
            padding-right: 8px;
        }
        input:focus {
            border-color: #ff1694;
        }
    }
    .contining_delivery_payment {
        margin-right: 53px;
        margin-left: 53px;
        margin-top: 30px;
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 20px;
            text-align: right;
        }
    }
    .contining_payment {
        h5 {
            background: #ff1694;
            height: 77px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0px;
            padding-right: 65px;
            padding-left: 65px;
            p {
                font-weight: 500;
                font-size: 30px;
                line-height: 44px;
                margin: 0px;
                span {
                    font-weight: 300;
                    font-size: 18px;
                    line-height: 26px;
                    margin-right: 5px;
                }
            }
        }
    }
}
#contining_items_style_15::-webkit-scrollbar-track {
    cursor: pointer;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #f5f5f5;
    border-radius: 10px;
}

#contining_items_style_15::-webkit-scrollbar {
    cursor: pointer;
    width: 10px;
    background-color: #f5f5f5;
}

#contining_items_style_15::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 10px;
    background-color: #fff;
    background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#a0a0a0), to(#a0a0a0), color-stop(0.6, #ebebeb));
}
