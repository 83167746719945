.continer {
  background-color: #404040;
  width: 100vw;
  height: 100vh;
  position: relative;
  img {
    width: 100vw;
    z-index: 1;
  }
  .color {
    background-color: #404040;
    width: 100vw;
    height: 3vw;
    position: absolute;
    top: 55vw;
    left: 0px;
  }
  .box {
    z-index: 2;
    position: absolute;
    top: 46vw;
    left: 16vw;
    width: 28vw;
    // background-color: red;
    display: flex;
    justify-content: space-between;
    margin-top: 1vw;
    cursor: auto;
    .right {
      width: 10vw;
    }
    .left {
      width: 14vw;
    }
    a {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;
      .icon_text {
        font-style: normal;
        font-weight: 100;
        font-size: 18px;
        color: #ffffff;
        font-size: 1.1vw;
      }
      // .icon {
        //   height: fit-content;
        svg {
          height: 1.3vw;
        }
        // }
      }
  }
}
@media only screen and (max-width: 1500px) {
  .continer {
  }
}
