.continer {
    display: flex;
    justify-content: space-around;
    .contining_right {
        width: 40vw;
        .contining_title {
            width: 100%;
            display: flex;
            align-items: flex-end;
            margin-top: 95px;
            h2 {
                font-weight: 500;
                font-size: 36px;
                line-height: 53px;
                text-align: right;
                margin-left: 5px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
            }
        }
        .button_for_payment {
            background: #ffffff;
            border: 1px solid #ff1694;
            border-radius: 15px;
            height: 62px;
            width: 100%;
            font-weight: 400;
            font-size: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 100px;
            margin-bottom: 150px;
            cursor: pointer;
        }
    }
    .contining_left {
        width: 40vw;
        margin-bottom: 150px;
        .aproval_regulations {
            display: flex;
            margin-right: 110px;
            align-items: center;
            input {
                width: fit-content;
                margin-left: 15px;
            }
            p {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
                margin: 0px;
                span {
                    color: #ff0000;
                    cursor: pointer;
                }
            }
        }
    }
    .contining_succeeded {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        width: 100vw;
        background-color: rgba(255, 255, 255, 0.877);
        position: fixed;
        z-index: 8000;
        top: 0px;
        .div_succeeded {
            background: black;
            min-width: 470px;
            min-height: 280px;
            margin: 40px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            padding: 40px;
            h2 {
                font-weight: 500;
                font-size: 24px;
                line-height: 35px;
                text-align: center;
            }
            h3 {
                font-weight: 400;
                font-size: 18px;
                line-height: 26px;
            }
            h4 {
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
            }
            .button_for_succeeded {
                background: #ffffff;
                border-radius: 15px;
                font-weight: 400;
                font-size: 24px;
                line-height: 28px;
                color: black;
                padding: 10px;
                width: 80%;
                margin-top: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                &:hover {
                    color: #ff1694;
                    
                }
            }
        }
    }
}
