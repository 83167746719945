.continer {
   position: fixed;
   top: 80px;
   max-height: calc(100vh - 80px);
   overflow-y: auto;
   padding-bottom: 200px;
   z-index: 100;
   background-color: white;
   width: 100vw;
   padding: 100px;
   box-shadow: 0px 8px 15px rgba(128, 128, 128, 0.623);

   .item_continer {
      width: 100%;
      margin-top: 8px;
      .box {
         display: flex;
         align-items: flex-end;
         cursor: pointer;
         .img_box {
            margin-left: 20px;
            img {
               width: 100px;
               height: 100px;
               object-fit: cover;
               border-radius: 5px;
            }
         }
      }
   }
   .not_found {
      color: gray;
      font-size: 30px;
      font-weight: bold;
   }
}
