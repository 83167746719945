.continer {
   background-color: black;
   width: 100vw;
   img {
      cursor: pointer;
   }
   .continer_titleTopFun {
      // height: 100px;
      color: white;
      display: flex;
      justify-content: center;
      padding-top: 50px;
      .box_item {
         margin-left: 10px;
         margin-right: 10px;
         cursor: pointer;
         position: relative;
         min-width: 220px;
         .title {
            padding: 5px;
            font-weight: 500;
            font-size: 18px;
            svg {
               margin-right: 15px;
            }
            padding-bottom: 17px;
         }
         .category_box {
            width: 100%;
            position: absolute;
            background-color: white;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            color: black;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.3s linear;
            display: flex;
            flex-direction: column;
            justify-content: center;
            z-index: 40;
            // margin-top: 17px;
            .category_tab {
               font-weight: 400;
               font-size: 18px;
               padding: 5px;
               &:hover {
                  background-color: rgb(238, 238, 238);
               }
            }
         }
         &:hover {
            .category_box {
               // display: block;
               visibility: visible;
               opacity: 1;
            }
         }
      }
   }
}
