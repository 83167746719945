.continer {
    display: flex;
    justify-content: space-around;
    height: 80px;
}

/*--------------------
Buttons
--------------------*/
.btn {
  display: block;
  background: hsl(86, 76%, 71%);
  color: hsl(0, 0, 100);
  text-decoration: none;
  margin: 20px 0;
  padding: 15px 15px;
  border-radius: 5px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;
    box-shadow: inset 0 3px 0 hsla(0, 0, 0, 0), 0 3px 3px hsla(0, 0, 0, 0.2);
    border-radius: 5px;
  }

  &:hover::after {
    background: hsla(0, 0, 0, 0.1);
    box-shadow: inset 0 3px 0 hsla(0, 0, 0, 0.2);
  }
}
.btn_social {
  padding-left: 64px;
  position: relative;
  z-index: 1;

  .fa {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 50px;
    height: 100%;
    text-align: center;
    background: hsla(0, 0, 0, 0.1);
    line-height: 3.2;
    border-radius: 5px 0 0 5px;
  }
}


/*--------------------
Form
--------------------*/

.MDBIcon {
  position: absolute;
  margin-top: 15px;
  margin-right: -30px;
  color: rgb(165, 165, 165);
}
.form {
  fieldset {
    border: none;
    padding: 0;
    margin: 20px 0;
    position: relative;
    

    input {
      width: 100%;
      height: 48px;
      color: hsl(0, 0, 20);
      padding: 15px 40px 15px 15px;
      border-radius: 5px;
      font-size: 14px;
      outline: none !important;
      border: 1px solid hsla(0, 0, 0, 0.3);
      box-shadow: inset 0 1px 4px hsla(0, 0, 0, 0.2);
      vertical-align: top;
      padding-right: 10px;
      padding-left: 40px;
    }
  }

  button {
    width: 100%;
    outline: none !important;
    background: linear-gradient(-5deg, hsl(86, 61%, 44%), hsl(86, 65%, 54%));
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 3px 0px hsla(86, 21%, 44%, 0.2);
    text-shadow: 0 2px 3px hsla(0, 0, 0, 0.2);
  }
}

/*--------------------
Signup
--------------------*/
.signup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 500px;
  background: hsl(0, 0, 100);
  border-radius: 10px;
  box-shadow: 0 3px 25px hsla(0, 0, 0, 0.2);
  overflow: hidden;
  display: flex;
}

.signup_connect,
.signup_classic {
  width: 100%;
  padding: 30px 50px;
}

.signup_connect {
  background: linear-gradient(134deg, hsl(44, 96%, 65%), hsl(34, 95%, 45%));
  color: hsl(0, 0, 100);

  h1 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 40px;
    text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
  }
}

.signup_classic {
  h2 {
    font-size: 16px;
    font-weight: normal;
    margin-top: 23px;
    margin-bottom: 43px;
    text-shadow: 0 2px 3px hsla(0, 0, 0, 0.1);
  }
}
.err_message {
  color: red;
  font-size: 10pt;
}
