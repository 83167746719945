.continer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .item_continer {
        position: relative;
        margin: 2vw;
        cursor: pointer;
        img {
            width: 43vw;
            height: 25vw;
            object-fit: cover;
            object-position: center;
        }
        .hover_button {
            top: 0px;
            width: 43vw;
            height: 25vw;
            position: absolute;
            background-color: rgba(1, 1, 1, 0.8);
            color: white;
            font-weight: 300;
            font-size: 5vw;
            line-height: 5vw;
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s, opacity 0.3s linear;
            display: flex;
            justify-content: center;
            align-items: center;
            .hover_button_text {
                margin: 2vw;
                padding: 2vw;
                border-style: solid;
                border-color: white;
                border-width: 2px;
            }
        }
        &:hover {
            .hover_button {
                visibility: visible;
                opacity: 1;
            }
        }
    }
}
