.continer {
    // position: relative;
    // padding-top: 500px;
    // background-color: black;
    .top_content {
        background-image: url("./assets/rimonCars.jpg");
        width: 80%;
        height: 800px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: auto;
        overflow: hidden;
        .top_round_yellow {
            position: relative;
            top: -140px;
            height: 650px;
            width: 650px;
            border-radius: 100%;
            background-color: #fff200c2;
            margin: auto;
        }
        .top_content_text_padding_top {
            padding-top: 200px;
        }
        .top_content_text_line {
            width: 250px;
            height: 3px;
            background-color: #EC008C;
            margin: auto;
        }
        .top_content_text {
            margin: 7px;
        }
        .top_content_text h2{
            margin: 0px;
            height: 80px;
            font-size: 70px;
            line-height: 80px;
            font-weight: bold;
        }
        .top_content_text_button {
            margin-top: 30px;
        }
        .top_content_text_button_A {
            font-size: 42px;   
        }
        .top_content_text_button_B {
            font-size: 42px;   
            font-weight: bold;
        }
        .top_content_button {
            margin: auto;
            background-color: #EC008C;
            color: white;
            font-size: 20px;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-right: 24px;
            padding-left: 24px;
            font-weight: bold;
            border-radius: 30px;
            margin-top: 30px;
            width: fit-content;
            transition: 0.3s;
            cursor: pointer;
        }
        .top_content_button:hover {
            background-color: #17a2b8;
        }
    }
    .bottom_skills {
        // position: absolute;
        display: flex;
        justify-content: center;
        // align-items: center;
        height: 130px;
        width: 100%;
        margin-top: -20px;
        .bottom_skills_item {
            width: 120px;
            margin-left: 20px;
            margin-right: 20px;
            .bottom_skills_item_icon {
                background-color: #00aeef;
                height: 76px;
                width: 76px;
                border-radius: 76px;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                transition: 0.3s;
                .icon {
                    transform: scale(0.8);
                }
                &:hover {
                    color: gray;
                    background-color: #EC008C;
                }
            }
            .bottom_skills_item_text {
                text-align: center;
                width: 120px;
                margin-top: 8px;
                font-size: 16px;
                font-weight: 600;
                line-height: 1em
            }
        }
    }
    .customers_recommend_continer {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .customers_recommend_continer_title {
            font-size: 40px;
            font-weight: 500;
            line-height: 1em;
        }
        .customers_recommend_continer_divider {
            margin-top: 15px;
            background-color: #EC008C;
            height: 3px;
            width: 250px;
        }
    }
}