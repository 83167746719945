.continer {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.705);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  .box {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    min-width: 450px;
    max-width: 80vw;
    .title {
      margin-top: 50px;
      font-weight: 500;
      font-size: 18px;
      margin-left: 80px;
      margin-right: 80px;
    }
    .error_message {
      margin-top: 20px;
      font-weight: normal;
      font-size: 17px;
    }
    .button {
      margin-top: 80px;
      height: 64px;
      width: 100%;
      background: #ff1694;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      cursor: pointer;
      color: #FFFFFF;
      &:hover {
        background: #d8117b;
      }
    }
  }
}
