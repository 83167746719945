.continer {
    margin-top: 84px;
    padding-bottom: 207px;
    .text_continer {
        width: 70vw;
        margin: auto;
        h1 {
            font-weight: 500;
            font-size: 24px;
            line-height: 28px;
            text-align: center;
            color: #ff1694;
            margin-bottom: 50px;
        }
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: right;
        }
    }
}
