.continer {
    .read_more {
        color: #ec008c;
        text-decoration: underline;
        text-align: right;
        font-size: 12;
        cursor: pointer;
    }
    .box {
        z-index: 200;
        position: fixed;
        top: 0px;
        left: 0px;
        height: 100vh;
        width: 100vw;
        background-color: rgba(128, 128, 128, 0.774);
        display: flex;
        justify-content: center;
        align-items: center;
        .read_more_insid_box {
            text-align: right;
            padding: 50px;
            background-color: white;
            border-radius: 30px;
            max-height: 90vh;
            position: relative;
            margin: 10vw;
            .button_close {
                background-color: rgb(248, 248, 248);
                width: 45px;
                height: 45px;
                border-radius: 45px;
                position: absolute;
                z-index: 200;
                padding-top: 6px;
                padding-left: 0px;
                right: -15px;
                top: -15px;
                box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
                transition: 0.5s;
                &:hover {
                    transform: scale(1.1);
                }
                .close {
                    width: 32px;
                    height: 32px;
                    opacity: 0.3;
                    cursor: pointer;
                    transition: 0.3s;
                }
                .close:hover {
                    opacity: 1;
                }
                .close:before,
                .close:after {
                    margin-left: 7px;
                    position: absolute;
                    left: 15px;
                    content: " ";
                    height: 33px;
                    width: 2px;
                    background-color: #333;
                }
                .close:before {
                    transform: rotate(45deg);
                }
                .close:after {
                    transform: rotate(-45deg);
                }
            }
            .contind_details_carts_b {
                font-weight: bold;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
            }
            p {
                margin: 0px;
                font-weight: 300;
                font-size: 18px;
                line-height: 26px;
                text-align: right;
            }
        }
    }
}
